/**
 * 工具方法
 */
export const isLocal = ['localhost', '127.0.0.1', '10.0.18.151'].includes(window.location.hostname)
export const isDev = ['d628319c8f4bccc217b38c727bdbabbd0.lynx-ai.cn'].includes(window.location.host)

const CacheUser = 'user'
/**
 * 获取本地持久化的用户信息
 */
export const getLocalUser = () => {
  if (isLocal || isDev) {
    return {
      userId: 1,
      userName: '用户1'
    }
  }
  const userStr = window.localStorage.getItem(CacheUser)
  return userStr && JSON.parse(userStr)
}

/**
 * 本地持久化的用户信息
 */
export const setLocalUser = user => {
  return window.localStorage.setItem(CacheUser, JSON.stringify(user))
}

export const clearLocalUser = () => {
  return window.localStorage.setItem(CacheUser, '')
}

/**
 * 是否是正式环境
 */
export const isPro = window.location.host === 'catastrophy.club'

export const localLog = (flag, value) => {
  // if (!isTest) return
  console.log(flag, value === undefined ? '' : value)
}

export function parseChunk(str) {
  // if (str.includes('[DONE]')) {
  //   localLog('parse ori str', str.split('data: '))
  //   return null
  // }
  // console.log('before parseChunk', str);
  const res = str
    .split('data: ')
    .filter(i => i.includes('{'))
    .map(k => k.replace(/^\n+|\n+$/g, '').replace(/^\r+|\r+$/g, ''))
  // console.log('parseChunk', res);
  const strList = res.map(i => {
    try {
      const data = JSON.parse(i)
      return data?.choices[0]?.delta?.content
    } catch (error) {
      return '*'
    }
  })
  return strList.join('')
}

export function formatTime(timestamp) {
  return new Date(timestamp * 1000).toLocaleString().slice(0, -3)
}
