import { useCallback, useEffect, useState } from 'react'
import { Spin, message } from 'antd'
import { getFeiShuUser } from '@/libs/api'

import { AuthContext } from './auth-context'
import { getLocalUser, localLog, setLocalUser, clearLocalUser } from '../utils.js'

/**
 * 飞书授权登录provider
 */
export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState(null)
  const [launching, setLaunching] = useState(true)
  // const [isProd, setProd] = useState(false)

  useEffect(() => {
    // clearLocalUser()
    const user = getLocalUser()
    localLog('inter auth provider useEffect..., user: ', user)

    async function toLogin() {
      try {
        // { code: 200, data: { uid}}
        const res = await getFeiShuUser()
        localLog('getFeiShuUser res: ', res)
        // alert(`localUser: ${JSON.stringify(res)}`)
        if (res.code !== 200) {
          message.error(res.errMsg)
          return
        }
        setLocalUser({ userId: res.data.uid, userName: res.data.userName })
        setLaunching(false)
      } catch (error: any) {
        console.log('getInfo error:', error)
      }
    }
    if (!user) {
      toLogin()
      return
    }
    setLaunching(false)
  }, [])

  const signin = useCallback(newUser => {
    setUser(newUser)
  }, [])

  const signout = useCallback(() => {
    setUser(null)
  }, [])

  if (launching) {
    return <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signin,
        signout
        // isProd
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
