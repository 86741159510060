import React, { Suspense } from 'react'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { Routes, Route } from 'react-router-dom'
import {
  // message,
  Spin
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
// import Layout from '@/components/Layout'
import AuthProvider from './libs/hooks/auth-provider'

const Home = React.lazy(() => import('./components/Home.jsx'))
const CreateCourse = React.lazy(() => import('./routes/create-course'))
const CreateCourseEntry = React.lazy(() => import('./routes/create-course-entry'))
const Course = React.lazy(() => import('./routes/course'))
const Outline = React.lazy(() => import('./routes/outline'))
const OutlineOld = React.lazy(() => import('./routes/outline-old'))

// message.config({
//   top: 100,
//   maxCount: 3
// })

Spin.setDefaultIndicator(<LoadingOutlined spin />)

const App = React.memo(() => {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      {/* fallback={<div style={{ textAlign: 'center', marginTop: 80 }}>Loading...</div>} */}
      <Suspense fallback={<div className="inner-loading">{/* <Spin /> */}</div>}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="entry" element={<CreateCourseEntry />} />
              <Route path="create" element={<CreateCourse />} />
              <Route path="course/:id" element={<Course />} />
              <Route path="outline/:id" element={<Outline />} />
              <Route path="outline-history/:id" element={<OutlineOld />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Suspense>
    </QueryParamProvider>
  )
})

export default App
