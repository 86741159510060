import { getLocalUser, clearLocalUser, localLog } from './utils'
import { getLarkAppId } from './config'

const larkAppid = getLarkAppId()
/**
 * api请求
 */
export const isLocal = !window.location.hostname.includes('catastrophy')
const defaultErrorMsg = 'net error'

/**
 * post工具方法
 */
async function post(url, body) {
  const user = getLocalUser()
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: user ? `Bearer ${user.token}` : ''
    },
    body: JSON.stringify(body)
  })
    .then(res => {
      console.log('post res', res.status)
      // token过期了
      if (res.status === 401) {
        clearLocalUser()
        // window.location.hash = '/login'
        window.location.reload()
        return null
      }
      return res.json()
    })
    .catch(error => {
      console.log(error)
      return { code: 500, msg: defaultErrorMsg }
    })
  return res
}

/**
 * get工具方法
 * @todo 捕获到401鉴权异常时，跳转到登录页，清除本地缓存
 */
async function get(url) {
  const user = getLocalUser()
  // console.log('before get ', user && user.token)
  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: user ? `Bearer ${user.token}` : ''
    }
  })
    .then(res => {
      // token过期了
      if (res.status === 401) {
        clearLocalUser()
        // window.location.hash = '/login'
        window.location.reload()
        return null
      }
      return res.json()
    })
    .catch(e => {
      console.log('get error', e)
      return null
    })
  return result
}

/**
 * 首次访问页面检测本地token是否过期
 */
export async function detect(token) {
  const data = await get(`/api/detect?token=${detect}`)
  if (data.code === 200) return true
  return false
}

/***********飞书登录 ***********/
/**
 * 获取飞书的临时授权码code
 */
export function getFeiShuCode() {
  if (!window.tt) {
    return {
      status: false,
      errMsg: '请在飞书app内打开该页面'
    }
  }
  return new Promise(resolve => {
    if (window.tt.requestAccess) {
      window.tt.requestAccess({
        // 网页应用 App ID
        appID: larkAppid,
        scopeList: [],
        success: res => {
          // 用户授权后返回预授权码
          const { code } = res
          resolve({
            status: true,
            code
          })
        },
        fail: error => {
          // 需要额外根据errno判断是否为 客户端不支持requestAccess导致的失败
          const { errno, errString } = error
          if (errno === 103) {
            // 客户端版本过低，不支持requestAccess，需要改为调用requestAuthCode
            window.tt.requestAuthCode({
              // 网页应用 App ID
              appId: larkAppid,
              success: res => {
                // 用户免登录后返回预授权码
                const { code } = res
                resolve({
                  status: true,
                  code
                })
              },
              fail: error => {
                // 免登失败，返回相应的errno和errString
                const { errno, errString } = error
                console.log('window.tt.requestAccess fail')
                resolve({
                  status: false,
                  errMsg: errString,
                  errCode: errno
                })
              }
            })
          } else {
            // 用户拒绝授权或者授权失败
            console.log(`用户拒绝授权或者授权失败: ${JSON.stringify(errString)}`)
            resolve({
              status: false,
              errMsg: `requestAccess调用失败： ${errString}`
            })
          }
        }
      })
    } else {
      // JSSDK版本过低，不支持requestAccess，需要改为调用requestAuthCode
      window.tt.requestAuthCode({
        // 网页应用 App ID
        appId: larkAppid,
        success: res => {
          // 用户免登录后返回预授权码
          const { code } = res
          resolve({
            status: true,
            code
          })
        },
        fail: error => {
          // 免登失败，返回相应的errno和errString
          const { errno, errString } = error
          console.log('window.tt.requestAuthCode fail')
          resolve({
            status: false,
            errMsg: errString,
            errCode: errno
          })
        }
      })
    }
  })
}

// function getfeishuCode() {
//   window.location.href = `https://accounts.feishu.cn/open-apis/authen/v1/authorize?client_id=cli_a7b67fd894b9500d&redirect_uri=${encodeURIComponent(window.location.href)}&scope=bitable:app:readonly%20contact:contact&state=RANDOMSTRING`
// }

export async function getFeiShuUser() {
  const codeInfo = await getFeiShuCode()
  // 获取code失败
  if (!codeInfo.status) {
    localLog('获取飞书临时授权码失败', codeInfo.errMsg)
    return {
      code: 400,
      errMsg: codeInfo.errMsg
    }
  }

  // 获取当前用户的信息
  const res = await get(`/api/feishu/login?code=${codeInfo.code}`)
    // .then(res => {
    //   if (res.code === 200 && res.data) {
    //     window.localStorage.setItem('user', JSON.stringify(res.data))
    //     return res.data
    //   }
    //   return false
    // })
    .catch(e => {
      localLog('调用获取飞书用户信息失败', e.message)
      return {
        code: 500,
        errMsg: e.message
      }
    })
  return res
}

/*************************** api ****************************/

/*************************** 课程相关 ****************************/
/**
 * 获取当前用户的课程数据列表
 */
export async function getCourses(arg) {
  const user = getLocalUser()
  const res = await get(`/api/course/list?sort=${arg.sort}&pn=${arg.pn}&user_id=${user.userId}&limit=${arg.size}`)
  return res
}

/**
 * 获取某个课程详情和对应课纲列表数据
 */
export async function getCourse(id) {
  const res = await get(`/api/course/detail?course_id=${id}`)
  return res
}

/**
 * 删除某个课程
 */
export async function delCourse(id) {
  const user = getLocalUser()
  const body = Object.assign(
    { updated_by: user.userId, updated_user: user.userName },
    {
      course_id: id
    }
  )
  const res = await post(`/api/course/delete`, body)
  return res
}

/**
 * 编辑课程
 */
export async function updateCourse(ops) {
  const user = getLocalUser()
  const body = Object.assign({ updated_by: user.userId, updated_user: user.userName }, ops)
  const res = await post('/api/course/update', body)
  return res
}

/**
 * 搜索课程
 */
export async function searchCourses(content) {
  const user = getLocalUser()
  const res = await get(`/api/course/search?content=${content}&user_id=${user.userId}`)
  return res
}

/*************************** 课纲相关 ****************************/
/**
 * 获取最新的课纲详情
 */
export async function getOutline(id) {
  const user = getLocalUser()
  const res = await get(`/api/outline/detail?outline_id=${id}&user_id=${user.userId}`)
  return res
}

/**
 * 获取某个历史版本的课纲详情
 */
export async function getOldOutline(id) {
  const res = await get(`/api/outline/record/detail?id=${id}`)
  return res
}
/**
 * 获取某个课纲所有历史版本
 */
export async function getOutlines(id) {
  const res = await get(`/api/outline/record?outline_id=${id}`)
  return res
  // return {
  //   code: 200,
  //   data: [
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     },
  //     {
  //       outline_name: '阿斯蒂芬',
  //       created_date: Date.now() / 1000,
  //       user_name: 'asdf '
  //     }
  //   ]
  // }
}

/**
 * 删除某个最新课纲
 * @todo 删除某个课程下的某个课纲(根据参数判断是删除所有历史版本还是删除某个历史版本)
 */
export async function delOutline(id) {
  const user = getLocalUser()
  const res = await post(`/api/outline/delete`, {
    outline_id: id,
    updated_by: user.userId,
    updated_user: user.userName
  })
  return res
}

/**
 * 创建课程（首次直接创建课纲）
 */
export async function createCourse(ops) {
  const user = getLocalUser()
  const body = Object.assign({ user_id: user.userId, user_name: user.userName }, ops)
  const res = await post('/api/course/add', body)
  return res
}

/**
 * 提交课纲
 */
export async function createOutline(ops) {
  const user = getLocalUser()
  const body = Object.assign({ user_id: user.userId, user_name: user.userName }, ops)
  const res = await post('/api/outline/add', body)
  return res
}

/**
 * 编辑最新版课纲（编辑课纲title、ai生成内容等）
 */
export async function updateOutline(ops) {
  const user = getLocalUser()
  const body = Object.assign({ updated_by: user.userId, updated_user: user.userName }, ops)
  const res = await post('/api/outline/update', body)
  return res
}

/**
 * 重新生成课纲
 */
export async function rebuildOutline(outline) {
  const user = getLocalUser()
  const body = Object.assign({}, outline, {
    updated_by: user.userId,
    updated_user: user.userName
  })
  delete body.course_id
  const res = await post('/api/outline/retry', body)
  return res
}

/**
 * 将某个历史版本课纲设置为最新版本
 */
export async function setNewestOutline(ops) {
  const body = { ...ops }
  delete body.course_id
  const res = await post('/api/outline/update_record', body)
  return res
}

/*************************** 打磨助手相关 ****************************/
/**
 * 获取聊天历史记录
 */
export async function getChatRecord(outlineId) {
  const user = getLocalUser()
  const res = await get(`/api/chat/polish/record?outline_id=${outlineId}&user_id=${user.userId}`)
  return res
}

/**
 * 保存一轮聊天记录
 */
export async function saveChat(ops) {
  const user = getLocalUser()
  const body = Object.assign({ user_id: user.userId, user_name: user.userName }, ops)
  const res = await post('/api/chat/polish/add', body)
  return res
}

/**
 * 删除某个聊天
 */
export async function delChat(ops) {
  const user = getLocalUser()
  const body = Object.assign({ updated_by: user.userId, updated_user: user.userName }, ops)
  const res = await post('/api/chat/polish/delete', body)
  return res
}

/**
 * 重新生成某个ai聊天
 */
export async function updateChat(ops) {
  const user = getLocalUser()
  const body = Object.assign({ updated_by: user.userId, updated_user: user.userName }, ops)
  const res = await post('/api/chat/polish/update', body)
  return res
}

/*************************** 文本书面化ai相关 ****************************/

/**
 * 保存ai生成的内容
 */
export async function saveAiRes(ops) {
  const user = getLocalUser()
  const res = await post('/api/chat/formal/add', {
    content: ops.content,
    result: ops.result,
    user_id: user.userId,
    user_name: user.userName
  })
  return res
}

/**
 * 分页获取历史记录
 */
export async function getAiRecord(pn) {
  const user = getLocalUser()
  const res = await get(`/api/chat/formal/record?pn=${pn}&user_id=${user.userId}`)
  return res
}
