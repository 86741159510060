export const getLarkAppId = () => {
  const port = window.location.port
  // gjr环境
  if (port === '9007') return 'cli_a7b67fd894b9500d'
  // lynx环境
  if (port === '9008') return 'cli_a7cea4d6e772500e'
  // Gao wei线上环境, 待提供
  if (port === '9006') return 'cli_a7cd62b742ef100b'
  // 本地环境
  return 'cli_a7b67fd894b9500d'
}
